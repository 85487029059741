const supportsStorage = () => {
  const mod = 'modernizr';

  try {
    window.localStorage.setItem(mod, mod);
    window.localStorage.removeItem(mod);
    return true;
  } catch (e) {
    return false;
  }
};

const addToStorage = (key, data, expirationTimeInMinutes) => {
  if (!supportsStorage()) {
    return false;
  }

  const storageData = {
    value: JSON.stringify(data),
    ...(expirationTimeInMinutes && {
      timestamp: new Date().getTime() + expirationTimeInMinutes * 60 * 1000
    })
  };

  window.localStorage.setItem(key, JSON.stringify(storageData));

  return true;
};

const getFromStorage = key => {
  if (!supportsStorage()) {
    return false;
  }

  const data = window.localStorage.getItem(key);

  if (!data) {
    return null;
  }

  const parsed = JSON.parse(data);

  if (!parsed.value) {
    return null;
  }

  // If no timestamp exists, treat as infinite storage
  const parsedStorageValue = JSON.parse(parsed.value);
  if (!parsed.timestamp) {
    return parsedStorageValue;
  }

  return new Date().getTime() < parsed.timestamp && parsedStorageValue;
};

const removeFromStorage = key => {
  if (!supportsStorage()) {
    return;
  }

  window.localStorage.removeItem(key);
};

const allKeys = () => {
  if (!supportsStorage()) {
    return [];
  }

  return Object.keys(window.localStorage);
};

export default {
  allKeys,
  addToStorage,
  getFromStorage,
  removeFromStorage
};
